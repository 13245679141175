import React, { Component } from 'react';
import './App.css';
import Header from './components/Header/Header';
import ResumeContainer from './components/ResumeContainer/ResumeContainer';
import ResumeForm from './components/ResumeForm/ResumeForm';
import { PDFExport } from '@progress/kendo-react-pdf';
import ResumeControls from './components/ResumeControls/ResumeControls';

let logo = require('./writtyLogo.svg');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProgress: 'landing',
      submitted: false,
      info: {
        bodyInfo: {
          personalInformation: {
            fullName: 'Guy Person',
            jobTitle: 'Cool Dude',
            personalStatement: `My name is Guy Person, I'm a super cool dude that does cool guy things. I've been doing cool guy stuff for four years, and specialize in making things cooler. In my work, I have repeatedly increased coolness rates by 15-20%. I am looking to continue doing cool things and further my skills to the benefit of your company`
          },
          workExperiences: [
            {
              position: 'Refrigerator Salesman',
              companyName: 'Chilly Box',
              workStartDate: '11/17',
              workEndDate: 'Present',
              workSummary: `It's funny because I am a cool guy, and I sell fridges. It doesn't really get funnier than that.`
            }
          ],
          educationExperiences: [
            {
              degree: 'Advanced Cool Guy',
              institutionName: 'School of Cool Guy Advancement',
              educationStartDate: '9/13',
              educationEndDate: '4/17',
              educationSummary: 'I got to do some really awesome things with some great people for a while. Graduated top of class with research experience.'
            }
          ]
        },
        sideBarInfo: {
          imageSource: 'https://projekts.co/wp-content/uploads/2018/05/Steve_01-1-389x475.jpg',
          contactInformation: {
            phoneNumber: 'pNumber',
            emailAddress: 'eAddress',
            LinkedInUrl: 'liUrl'
          },
          skills: [
            'Skill One',
            'Skill Two'
          ]
        }
      },
      styles: {
        font: 'Lato',
        accentColor: '#000',
        layout: 'default'
      }
    }

    this.useFormData = this.useFormData.bind(this);
    this.setFont = this.setFont.bind(this);
    this.setLayout = this.setLayout.bind(this);
  }

  useFormData(formData) {
    let data = formData;
    this.setState({
      info: data
    });
  }

  exportPDF = () => {
    this.resume.save();
  }

  setFont(font) {
    let theFont = font;

    this.setState({
      styles: { 
        font: theFont,
        layout: this.state.styles.layout
       }
    })
  }

  setLayout(layout) {
    let theLayout = layout;

    this.setState({
      styles: {
        layout: theLayout,
        font: this.state.styles.font
      }
    })
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  render() {
    return (
      <div className="app">
        <Header logo={logo} />
        <div className="app-pre-body">
        <ResumeForm setInfo={this.useFormData} />
        <div className="app-body">
        <ResumeControls setLayout={this.setLayout} setFont={this.setFont} />
          <PDFExport
            paperSize={'Letter'}
            fileName="_____.pdf"
            title=""
            subject=""
            keywords=""
            ref={(r) => this.resume = r}>
            <ResumeContainer styles={this.state.styles} info={this.state.info} />
          </PDFExport>
          <button className="dlButton" onClick={this.exportPDF}>Download</button>
        </div>
        </div>
        
      </div>
    );
  }
}

export default App;
