import React, { Component } from 'react';
import './ContactInfo.css';

export class ContactInfo extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== this.props;
    }

  render() {
    return (
        <div className="contact-info center-aligned-col">
            <h4>Contact</h4>
            <ul>
                <li>{this.props.info.phoneNumber}</li>
                <li>{this.props.info.emailAddress}</li>
                <li>{this.props.info.linkedInUrl}</li>
            </ul>
        </div>
    )
  }
}

export default ContactInfo
