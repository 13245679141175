import React, { Component } from 'react';
import './Header.css';
export class Header extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
       
    }
  }
  
  render() {
    return (
      <div className="header">
        <div className="logo-container">
            <img alt="writty logo" src={this.props.logo} />
        </div>
      </div>
    )
  }
}

export default Header
