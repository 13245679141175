import React, { Component } from 'react';
import './ImageSourceForm.css';

export class ImageSourceForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            imageSource: 'Default'
        }

        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleTextChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.passUp(this.state.imageSource, 'imageSource');
        }
    }

    render() {
        return (
            <div>
                <label>
                    Link to an image of yourself
                        <input type="text" name="imageSource" onChange={this.handleTextChange} value={this.state.imageSource} />
                </label>
            </div>
            
        )
    }
}

export default ImageSourceForm
