import React, { Component } from 'react';
import './FontSelector.css';
import availableFonts from './AvailableFonts';

export class FontSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selection: 'Lato'
        }

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    }

    handleOptionChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    renderOptions() {
        let list = availableFonts.map(option => {
            return <option name={option.name} value={option.name}>{this.capitalizeFirstLetter(option.name)}</option>
        })

        return list;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.setFont(this.state.selection);
            console.log("update");
        }
    }

    render() {
        return (
            <div>
                <form>
                    <label>
                        Choose a font 
                        <br />
                        <select onChange={this.handleOptionChange} name="selection" value={this.state.selection}>
                            {this.renderOptions()}
                        </select>
                    </label>
                </form>
            </div>
        )
    }
}

export default FontSelector
