import React, { Component } from 'react';
import './ResumeForm.css';
import BodyInfoForm from './form-components/BodyInfoForm/BodyInfoForm';
import SideBarInfoForm from './form-components/SideBarInfoForm/SideBarInfoForm';


export class ResumeForm extends Component {



    constructor(props) {
        super(props);

        this.state = {
            bodyInfo: {
                personalInformation: {
                    fullName: '',
                    jobTitle: 'title',
                    personalStatement: 'pStatement'
                },
                workExperiences: [
                    {
                        position: 'Web Developer',
                        companyName: 'Orpheus, Inc.',
                        workStartDate: '11/17',
                        workEndDate: 'Present',
                        workSummary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
                    }
                ],
                educationExperiences: [
                    {
                        degree: 'Advanced Cool Guy',
                        institutionName: 'School of Cool Guy Advancement',
                        educationStartDate: '9/13',
                        educationEndDate: '4/17',
                        educationSummary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
                    }
                ]
            },
            sideBarInfo: {
                imageSrc: 'https://projekts.co/wp-content/uploads/2018/05/Steve_01-1-389x475.jpg',
                  contactInformation: {
                    phoneNumber: 'pNumber',
                    emailAddress: 'eAddress',
                    LinkedInUrl: 'liUrl'
                },
                skills: [
                    'Skill One',
                    'Skill Two'
                ]
            }
        }

        this.passInfo = this.passInfo.bind(this);
    }


    passInfo(info, target) {
        let toUpdate = target;
        this.setState({
            [toUpdate]: info
        });
        console.log(this.state[toUpdate]);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) {
          this.props.setInfo(this.state);
        }
      }


    render() {
        return (
            <form onSubmit={this.formSubmit} className="resume-form">
                <BodyInfoForm passUp={this.passInfo} />
                <SideBarInfoForm passUp={this.passInfo} />
            </form>
        )
    }
}



export default ResumeForm
