import React, { Component } from 'react';
import './ResumeBody.css';
import PersonalInfo from '../PersonalInfo/PersonalInfo';
import EducationList from '../EducationList/EducationList';
import WorkExperienceList from '../WorkExperienceList/WorkExperienceList';

export class ResumeBody extends Component {
  render() {
    return (
      <div className="resume-body">
        <PersonalInfo personalInformation={this.props.bodyInfo.personalInformation} />
        <EducationList educationExperiences={this.props.bodyInfo.educationExperiences} />
        <WorkExperienceList wrkArray={this.props.bodyInfo.workExperiences} />
      </div>
    )
  }
}

export default ResumeBody
