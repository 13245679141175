import React, { Component } from 'react';
import './NewWorkForm.css';

export class NewWorkForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      workExperiences: [],
      position: '',
      companyName: '',
      workStartDate: '',
      workEndDate: '',
      workSummary: ''
    }

    this.addToWorkArray = this.addToWorkArray.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }


  newWorkExperienceFactory(position, companyName, workStartDate, workEndDate, workSummary) {
    const workExperience = {
      position,
      companyName,
      workStartDate,
      workEndDate,
      workSummary
    }

    return workExperience;
  }

  addToWorkArray() {
    let workExperiences = this.state.workExperiences;
    let toAdd = this.newWorkExperienceFactory(this.state.position, this.state.companyName, this.state.workStartDate, this.state.workEndDate, this.state.workSummary);
    this.setState({
      workExperiences: [...workExperiences, toAdd],
      position:'',
      companyName: '',
      workStartDate: '',
      workEndDate: '',
      workSummary: ''
    })
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.passUp(this.state.workExperiences, 'workExperiences');
    }
  }

  render() {
    return (
      <div className="card">
        <label>
          Name company or organization that you've worked at previously.
              <input type="text" name="companyName" onChange={this.handleTextChange} value={this.state.companyName} />
        </label>
        <label>
          What did you work as?
              <input type="text" name="position" onChange={this.handleTextChange} value={this.state.position} />
        </label>
        <label>
          When did you start?
              <input type="text" name="workStartDate" onChange={this.handleTextChange} value={this.state.workStartDate} />
        </label>
        <label>
          When did you stop working their?
              <input type="text" name="workEndDate" onChange={this.handleTextChange} value={this.state.workEndDate} />
        </label>
        <label>
          Can you summarize the experience in one to two sentences?
              <textarea name="workSummary" onChange={this.handleTextChange} value={this.state.workSummary} />
        </label>
        <button type="button" onClick={this.addToWorkArray}>Add Work</button>
      </div>
    )
  }
}

export default NewWorkForm
