import React, { Component } from 'react';
import './BodyInfoForm.css';
import NewEducationForm from '../NewEducationForm/NewEducationForm';
import NewWorkForm from '../NewWorkForm/NewWorkForm';
import PersonalInfoForm from '../PersonalInfoForm/PersonalInfoForm';

export class BodyInfoForm extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         personalInformation:{},
         workExperiences:[],
         educationExperiences: []
      }

      this.passInfo = this.passInfo.bind(this)
    }

    passInfo(info, target) {
      let toUpdate = target;
      this.setState({
        [toUpdate]: info
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state !== prevState) {
        this.props.passUp(this.state, 'bodyInfo');
      }
    }

   
    
  render() {
    return (
      <div>
        <PersonalInfoForm passUp={this.passInfo} />
        <NewEducationForm passUp={this.passInfo} />
        <NewWorkForm passUp={this.passInfo} />
      </div>
    )
  }
}

export default BodyInfoForm
