import React, { Component } from 'react';
import './PersonalInfo.css';

export class PersonalInfo extends Component {
  render() {
    return (
      <div className="personal-info">
        <h2 className="name">{this.props.personalInformation.fullName}</h2>
        <h3 className="job-title">{this.props.personalInformation.jobTitle}</h3>
        <p className="personal-statement">{this.props.personalInformation.personalStatement}</p>
        </div>
    )
  }
}

export default PersonalInfo
