import React, { Component } from 'react';
import './Skills.css';

export class Skills extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       
    }

    this.renderSkills = this.renderSkills.bind(this);
  }
  

  renderSkills() {
    return this.props.skills.map((skill, index) => {
      return <li key={"skill" + index}>{skill}</li>
    })
  }

  

  render() {
    return (
      <div className="skills center-aligned-col">
          <h4>Skills</h4>
          <ul>
              {this.renderSkills()}
          </ul>
      </div>
    )
  }
}

export default Skills
