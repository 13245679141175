import React, { Component } from 'react';
import './ResumeContainer.css';
import './fonts.css';
import ResumeBody from '../ResumeBody/ResumeBody';
import SideColumn from '../SideColumn/SideColumn';



export class ResumeContainer extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
       
    }
  }

  
  render() {
    return (
      <div className={`resume-container ${this.props.styles.layout}-layout ${this.props.styles.font}`} >
      <SideColumn sideInfo={this.props.info.sideBarInfo} />
      <ResumeBody bodyInfo={this.props.info.bodyInfo} />
      </div >
    )
  }
}

export default ResumeContainer
