import React, { Component } from 'react';
import './WorkExperience.css';


/*
              position: 'Web Developer',
              companyName: 'Orpheus, Inc.',
              workStartDate: '11/17',
              workEndDate: 'Present',
              workSummary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
*/ 

export class WorkExperience extends Component {
  render() {
    return (
      <div className="work-experience">
        <h4 className="title">{this.props.experience.position} - {this.props.experience.companyName}</h4>
        <p className="dates">{this.props.experience.workStartDate} - {this.props.experience.workEndDate}</p>
        <p>{this.props.experience.workSummary}</p>
        </div>
    )
  }
}

export default WorkExperience
