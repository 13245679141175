import React, { Component } from 'react';
import './ProfileImage.css';
import defaultAvatar from './defaultAvatar2.png';


export class ProfileImage extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  

  render() {
    const url = this.props.src === '' ? defaultAvatar : this.props.src;
    return (
      <div className="profile-image" style={{backgroundImage: `url(${url})`}}>
      </div>
    )
  }
}

export default ProfileImage
