import React, { Component } from 'react';
import './ResumeControls.css';
import FontSelector from './FontSelector/FontSelector';
import LayoutSelector from './LayoutSelector/LayoutSelector';

export class ResumeControls extends Component {
    constructor(props) {
        super(props)

        this.state = {
            font: 'Lato'
        }

        this.updateFont = this.updateFont.bind(this);
    }

    updateFont(font) {
        let newFont = font;
        this.setState({
            newFont
        })
    }


    render() {
        return (
            <div className="resume-controls">
                <FontSelector setFont={this.props.setFont} />
                <LayoutSelector setLayout={this.props.setLayout} />
            </div>
        )
    }
}

export default ResumeControls
