import React, { Component } from 'react';
import './WorkExperienceList.css';
import WorkExperience from '../WorkExperience/WorkExperience';

export class WorkExperienceList extends Component {
  
  constructor(props) {
    super(props)
  
    this.state = {
       
    }

  }
 
  renderWorkItems() {
    return this.props.wrkArray.map((wExp, index) => {
      return <WorkExperience key={"work"+index} experience={wExp} />
    })
  }

  render() {
    return (
      <div className="work-experience-list">
      <h3>Work Experience</h3>
        {this.renderWorkItems()}
      </div>
    )
  }
}

export default WorkExperienceList
