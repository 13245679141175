import React, { Component } from 'react';
import './SideColumn.css';
import ProfileImage from '../ProfileImage/ProfileImage';
import Skills from '../Skills/Skills';
import ContactInfo from '../ContactInfo/ContactInfo';

export class SideColumn extends Component {
  render() {
    return (
      <div className="side-column">
        <ProfileImage src={this.props.sideInfo.imageSource} />
        <ContactInfo info={this.props.sideInfo.contactInformation} />
        <Skills skills={this.props.sideInfo.skills} />
      </div>
    )
  }
}

export default SideColumn
