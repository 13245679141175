import React, { Component } from 'react';
import './NewEducationForm.css';

export class NewEducationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      educationExperiences: [],
      degree: '',
      institutionName: '',
      educationStartDate: '',
      educationEndDate: '',
      educationSummary: ''
    }

    this.handleTextChange = this.handleTextChange.bind(this);
    this.addToEducationArray = this.addToEducationArray.bind(this);
  }

  newEducationExperienceFactory(degree, institutionName, educationStartDate, educationEndDate, educationSummary) {
    const educationExperience = {
      degree,
      institutionName,
      educationStartDate,
      educationEndDate,
      educationSummary
    }

    return educationExperience;
  }

  addToEducationArray() {
    let educationExperiences = this.state.educationExperiences;
    let toAdd = this.newEducationExperienceFactory(this.state.degree, this.state.institutionName, this.state.educationStartDate, this.state.educationEndDate, this.state.educationSummary);
    this.setState({
      educationExperiences: [...educationExperiences, toAdd],
      degree:'',
      institutionName: '',
      educationStartDate: '',
      educationEndDate: '',
      educationSummary: ''
    })
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state);
    if (prevState !== this.state) {
      this.props.passUp(this.state.educationExperiences, 'educationExperiences');
    }
  }


  render() {
    return (
      <div>
        <label>
          Let's talk about your education. Name an institution you've received an education from.
              <input type="text" name="institutionName" onChange={this.handleTextChange} value={this.state.institutionName} />
        </label>
        <label>
          What degree or certificate did you receive?
              <input type="text" name="degree" onChange={this.handleTextChange} value={this.state.degree} />
        </label>
        <label>
          When did you enter the program?
              <input type="text" name="educationStartDate" onChange={this.handleTextChange} value={this.state.educationdStartDate} />
        </label>
        <label>
          When did you graduate?
              <input type="text" name="educationEndDate" onChange={this.handleTextChange} value={this.state.educationEndDate} />
        </label>
        <label>
          Summarize your experience
              <textarea name="educationSummary" onChange={this.handleTextChange} value={this.state.educationSummary} />
        </label>
        <button onClick={this.addToEducationArray} type="button">Add Education</button>
      </div>
    )
  }
}

export default NewEducationForm
