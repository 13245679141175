import React, { Component } from 'react';
import './PersonalInfoForm.css';

export class PersonalInfoForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fullName: 'Default',
      jobTitle: 'Job Title',
      personalStatement: 'Fugiat incididunt ipsum tempor pariatur. Amet in ad sunt in irure ea Lorem laborum amet cillum amet. Sit ad eu excepteur eiusmod qui laborum consectetur. Eiusmod eiusmod voluptate ut mollit magna fugiat est cillum id.'
    }

    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
        this.props.passUp(this.state, 'personalInformation');
    } else {
        return
    }
}

  render() {
    return (
      <div>
        <label>
          What is your full name?
              <input type="text" placeholder="Guy Person" name="fullName" onChange={this.handleTextChange} value={this.state.fullName} />
        </label>
        <label>
          What is your current job position?
              <input type="text" name="jobTitle" onChange={this.handleTextChange} value={this.state.jobTitle} />
        </label>
        <label>
          Tell me about yourself in three sentences or fewer.
              <textarea name="personalStatement" onChange={this.handleTextChange} value={this.state.personalStatement} />
        </label>
      </div>
    )
  }
}

export default PersonalInfoForm
