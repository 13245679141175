const availableFonts = [
    {
        name: 'lato'
    },
    {
        name: 'overpass',
    },
    {
        name: 'roboto'
    }
]

export default availableFonts;