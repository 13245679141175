import React, { Component } from 'react';
import './ContactInfoForm.css';

export class ContactInfoForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      phoneNumber: 'Phone Number',
      emailAddress: 'Email Address',
      linkedInUrl: 'LinkedIn URL',
    }

    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.passUp(this.state, 'contactInformation');
    }

  }

  render() {
    return (
      <div>
        <label>
          Where can an interviewer call you?
              <input type="text" name="phoneNumber" onChange={this.handleTextChange} value={this.state.phoneNumber} />
        </label>
        <label>
          And how about an email address?
              <input type="text" name="emailAddress" onChange={this.handleTextChange} value={this.state.emailAddress} />
        </label>
        <label>
          Let's also use your LinkedIn profile
              <input type="text" name="linkedInUrl" onChange={this.handleTextChange} value={this.state.linkedInUrl} />
        </label>
      </div>
    )
  }
}

export default ContactInfoForm
