import React, { Component } from 'react';
import './SkillsListForm.css';

export class SkillsListForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            skills: [],
            skill: ''
        }
        
        this.handleTextChange = this.handleTextChange.bind(this);
        this.addToSkillsArray = this.addToSkillsArray.bind(this);
    }

    handleTextChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, this.props.passUp(this.state.skills, 'skills'));
    }

    addToSkillsArray() {
        let currSkills = this.state.skills;

        this.setState({
            skills: [...currSkills, this.state.skill],
            skill: ''
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.passUp(this.state.skills, 'skills');
        }
    }

    render() {
        return (
            <div className="card">
                <label>
                    Input a new skill
            <input type="text" name="skill" onChange={this.handleTextChange} value={this.state.skill} />
                </label>
            <button type="button" onClick={this.addToSkillsArray}>Add Skill</button>
            </div>
        )
    }
}

export default SkillsListForm
