import React, { Component } from 'react';
import './Education.css';

export class Education extends Component {
  render() {
    return (
      <div className="education-item">
        <h4 className="title"><span>{this.props.experience.degree}</span> - <span>{this.props.experience.institutionName}</span></h4>
        <p className="dates">{this.props.experience.educationStartDate} - {this.props.experience.educationEndDate}</p>
        <p className="summary">{this.props.experience.educationSummary}</p>
        </div>
    )
  }
}

export default Education
