import React, { Component } from 'react';
import './SideBarInfoForm.css';
import ImageSourceForm from '../ImageSourceForm/ImageSourceForm';
import ContactInfoForm from '../ContactInfoForm/ContactInfoForm';
import SkillsListForm from '../SkillsListForm/SkillsListForm';

export class SideBarInfoForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      imageSource: '',
      contactInformation: {},
      skills: []
    }

    this.passInfo = this.passInfo.bind(this);
  }

  passInfo(info, target) {
    let toUpdate = target;
    this.setState({
      [toUpdate]: info
    });

    console.log(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.passUp(this.state, 'sideBarInfo')
    }
  }



  render() {
    return (
      <div>
        <ImageSourceForm passUp={this.passInfo} />
        <ContactInfoForm passUp={this.passInfo} />
        <SkillsListForm passUp={this.passInfo} />
      </div>
    )
  }
}

export default SideBarInfoForm
