import React, { Component } from 'react';
import './EducationList.css';
import Education from '../Education/Education';

export class EducationList extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       
    }

    this.renderEducationItems = this.renderEducationItems.bind(this);
  }
  
  renderEducationItems() {
    return this.props.educationExperiences.map((eExp, index) => {
      return <Education key={"edu"+index} experience={eExp} />
    })
  }

  render() {
    return (
      <div className="education-list">
        <h3>Education</h3>
        {this.renderEducationItems()}
      </div>
    )
  }
}

export default EducationList
